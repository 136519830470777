import React, { useState } from 'react'
import { Box, Button, Card, Stack, TextField,Typography } from '@mui/material'
// import { useNavigate } from 'react-router-dom';
// import { authenticate } from '../services/authenticate';
// import userpool from '../userpool'

// import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { RoutePaths } from '../constants';

// export const authenticate=(Email,Password)=>{
//     return new Promise((resolve,reject)=>{
//         const user=new CognitoUser({
//             Username:Email,
//             Pool:userpool
//         });

//         user.setAuthenticationFlowType("USER_PASSWORD_AUTH")

//         const authDetails= new AuthenticationDetails({
//             Username:Email,
//             Password
//         });

//         user.authenticateUser(authDetails,{
//             onSuccess:(result)=>{
//                 console.log("login successful");
//                 resolve(result);
//             },
//             newPasswordRequired:(userAttributes, requiredAttributes)=>{
//               delete userAttributes.email
//               delete userAttributes.email_verified;
//               userAttributes.family_name = 'Anthony'
//               userAttributes.given_name = 'Robert'
//               userAttributes.name = 'Robert'
//               user.completeNewPasswordChallenge('11223344AAbbccdd!!', userAttributes, {
//                 onSuccess: (data) => {
//                   console.log(data);
//                 },
//                 onFailure: (err) => {
//                   alert(err)
//                 }
//               })
//             },
//             onFailure:(err)=>{
//                 console.log("login failed",err);
//                 reject(err);
//             }
//         });
//     });
// };

export const LoginForm = () => {

  // const Navigate = useNavigate();
  const { login } = useAuth()

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [loginErr,setLoginErr] = useState('');
  const navigate = useNavigate()

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === '' && password === '') {
        setEmailErr("Email is Required");
        setPasswordErr("Password is required")
        resolve({ email: "Email is Required", password: "Password is required" });
      }
      else if (email === '') {
        setEmailErr("Email is Required")
        resolve({ email: "Email is Required", password: "" });
      }
      else if (password === '') {
        setPasswordErr("Password is required")
        resolve({ email: "", password: "Password is required" });
      }
      else if (password.length < 6) {
        setPasswordErr("must be 6 character")
        resolve({ email: "", password: "must be 6 character" });
      }
      else {
        resolve({ email: "", password: "" });
      }
    });
  };

  const handleClick = () => {
    setEmailErr("");
    setPasswordErr("");
    validation()
      .then((res) => {
        if (res.email === '' && res.password === '') {
          login(email,password)
          .then((data)=>{
            setLoginErr('');
            console.log('Logged In')
            navigate(RoutePaths.HOME)
          },(err)=>{
            console.log(err);
            setLoginErr(err.message)
          })
          .catch(err=>console.log(err))
        }
      }, err => console.log(err))
      .catch(err => console.log(err));
  }

  return (
    <Stack justifyContent="space-between">
      <Card 
        variant="outlined" 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          width: '100%',
          padding: '32px',
          gap: '16px',
        }}
        >
        
          <TextField
            value={email}
            onChange={(e) => formInputChange("email", e.target.value)}
            label="Email"
            helperText={emailErr}
          />

          <TextField
            value={password}
            onChange={(e) => { formInputChange("password", e.target.value) }}
            type="password"
            label="Password"
            helperText={passwordErr}
          />
      
          <Button type='submit' variant='contained' onClick={handleClick}>Login</Button>
        
          <Typography variant="body">{loginErr}</Typography>
      </Card>
    </Stack>
    
  )
}

// export default Login