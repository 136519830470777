import { ButtonGroup, AppBar as MuiAppBar, Stack, Toolbar, Typography } from '@mui/material';
import { DataUploadButton } from './DataUploadButton';
import { DownloadButton } from './DownloadButton';
import { LogoutButton } from './LogoutButton';
import { useAuth } from '../context/AuthProvider';

import { useColorScheme } from '@mui/material/styles';

const ModeSwitcher = () => {
  const { mode, setMode } = useColorScheme();

  if (!mode) {
    return null;
  }

  return (
    <select
      value={mode}
      onChange={(event) => {
        setMode(event.target.value);
        // For TypeScript, cast `event.target.value as 'light' | 'dark' | 'system'`:
      }}
    >
      <option value="system">System</option>
      <option value="light">Light</option>
      <option value="dark">Dark</option>
    </select>
  );
}


export const AppBar = () => {
  const { user } = useAuth()
  return (
    <MuiAppBar>
      <Toolbar variant="regular">
        <Stack
          direction="row"
        >
          <Typography variant="h5">Shark Procurement Dashboard</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          flex={1}
          gap={2}
        >
          <DataUploadButton />
          <DownloadButton />
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          {user && user.username}
          {/* Sandy Cheeks */}
          <ModeSwitcher />
          <LogoutButton />
        </Stack>
      </Toolbar>
    </MuiAppBar>
  )
}