export const DecisionFormFields = {
  contractId: {
    key: "contractId",
    label: "Contract ID",
  },
  desiredTerm: {
    key: "desiredTerm",
    label: "Desired Term",
  },
  renewalDecision: {
    key: "renewalDecision",
    label: "Renewal Decision",
  },
  actionType: {
    key: "actionType",
    label: "Action Type",
  },
  zipId: {
    key: "zipId",
    label: "Zip ID",
  },
  tcv: {
    key: "tcv",
    label: "Forecasted TCV",
  },
  forecast: {
    key: "forecast",
    label: "In Year Forecast",
  },
  budget: {
    key: "budget",
    label: "In Year Budget",
  },
  decisionStatus: {
    key: "decisionStatus",
    label: "Decision Status",
  },
  needByDate: {
    key: "needByDate",
    label: "Need By Date",
  },
  contact: {
    key: "contact",
    label: "Sourcing Contact",
  },
  financePartner: {
    key: "financePartner",
    label: "LOB Finance Partner",
  },
}

export const RenewalDecisionOptions = [
  {
    value: "renew",
    label: "Renew",
  },
  {
    value: "terminate",
    label: "Terminate",
  },
]

export const DesiredTermOptions = [...Array(72).keys()].map(x => ({ value: ++x, label: x }))

export const ActionTypeOptions = [
  {
    value: 'growth',
    label: 'Growth',
  },
  {
    value: 'rationalize',
    label: 'Rationalize',
  },
  {
    value: 'risk',
    label: 'Risk',
  },
  {
    value: 'steadyState',
    label: 'Steady State',
  },
]

export const RoutePaths = {
  HOME: "/",
  LOGIN: "/login",
  SIGN_IN: "/signin",
}

/**
 * A validator to ensure the value is currency.
 * @param {*} value 
 * @returns 
 */
export const currencyValidator = (value) => {
  const currencyRegex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
  const isCurrency = currencyRegex.test(value)

  if (!isCurrency) {
    return "Please enter a valid dollar amount"
  }

  return ""
}