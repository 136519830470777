import { ThemeProvider, createTheme } from '@mui/material/styles';

const colorPalette = {
  palette: {
    primary: {
      // main: '#ff1744',
      main: '#ff0026',
    },
    secondary: {
      // main: '#3BB1f3',
      // main: '#FF00A6',
      main: '#00ffd9',
    },
  },
}

const theme = createTheme({
  colorSchemes: { 
    light: {...colorPalette},
    dark: {...colorPalette},
  },
  cssVariables: {
    colorSchemeSelector: 'class'
  }
});

export const AppTheme = ({children}) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
)
