import { CssBaseline } from "@mui/material";

import { Login } from './routes/Login';
import { Home } from './routes/Home';
import { Route, Routes } from 'react-router-dom';
import { SignIn } from './routes/SignIn';
import { AppTheme } from './theme';
import { ProtectedRoute } from './components/ProtectedRoute';

import { RoutePaths } from "./constants";

export const App = () => ( 
  <AppTheme>
    <CssBaseline enableColorScheme />
    <Routes>
      <Route element={ <ProtectedRoute /> }>
        <Route path={RoutePaths.HOME} element={ <Home/> } />
      </Route>
      <Route path={RoutePaths.LOGIN} element={ <Login/> } />
      <Route path={RoutePaths.SIGN_IN} element={ <SignIn/> } />
    </Routes>
  </AppTheme>
)
