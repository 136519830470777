import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    drawerOpen: false,
  },
  reducers: {
    openDrawer: (state) => {
      state.drawerOpen = true
    },
    closeDrawer: (state) => {
      state.drawerOpen = false
    }
  }
})

export const { openDrawer, closeDrawer } = appSlice.actions

export default appSlice.reducer