import { GridToolbarQuickFilter, DataGrid as MuiXDataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredRows, getRowId } from "../dataSlice";
import { setRowSelectionAndInitializeForm } from "../thunks";
import React from "react";
import { Portal } from "@mui/material";

const currencyFormatter = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
})

const usdPrice = {
  type: 'number',
  width: 130,
  valueFormatter: (value) => value ? currencyFormatter.format(value) : null,
  cellClassName: 'font-tabular-nums',
}

const columns = [
  { 
    field: 'expirationDate', 
    type: 'date', 
    headerName: 'Expiration Date', 
    valueGetter: (value) => {
      if (!value) {
        return value
      }

      return new Date(value)
    },
    minWidth: 125,
    // filterOperators: expirationDateOperators,
    // ...otherColumnProps 
  },
  { 
    field: 'supplierName',
    headerName: 'Supplier Name',
    minWidth: 115,
    // ...otherColumnProps
  },
  { 
    field: 'oem',
    headerName: 'OEM',
    // ...otherColumnProps,
  },
  { 
    field: 'contractType',
    headerName: 'Contract Type',
    minWidth: 125,
    // ...otherColumnProps
  },
  { 
    field: 'chargeOut',
    headerName: 'Charge out',
    // ...otherColumnProps
  },
  { 
    field: 'expenseType',
    headerName: 'Expense Type',
    minWidth: 115,
    // ...otherColumnProps
  },
  { 
    field: 'effectiveDate',
    headerName: 'Effective Date',
    minWidth: 110,
    // ...otherColumnProps
  },
  { 
    field: 'termType',
    headerName: 'Term Type',
    // ...otherColumnProps
  },
  { 
    field: 'contractAmount',
    headerName: 'Contract Amount',
    minWidth: 125,
    ...usdPrice
  },
  { 
    field: 'noticePeriod',
    headerName: 'Notice Period',
    minWidth: 115,
    // ...otherColumnProps
  },
  { 
    field: 'invoiceNumber',
    headerName: 'Invoice Number',
    minWidth: 125,
    // ...otherColumnProps
  },
  { 
    field: 'notes', 
    headerName: 'Notes', 
    // ...otherColumnProps 
  },
]

const CustomToolbar = (props) => (
  <React.Fragment>
    <Portal container={() => document.getElementById('filter-panel')}>
      <GridToolbarQuickFilter sx={{width: "100%"}} />
    </Portal>
  </React.Fragment>
)

export const DataGrid = () => {
  const dispatch = useDispatch()
  const rowId = useSelector(getRowId)
  const rows = useSelector(getFilteredRows)

  const handleRowSelection = (newRowSelectionModel) => {
    dispatch(setRowSelectionAndInitializeForm(newRowSelectionModel))
  }

  return (
    <MuiXDataGrid 
      rows={rows} 
      columns={columns}
      density="compact"
      disableMultipleRowSelection
      autosizeOptions={{
        includeHeaders: true,
      }}
      onRowSelectionModelChange={handleRowSelection}
      rowSelectionModel={rowId}
      slots={{
        toolbar: CustomToolbar
      }}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 25, page: 0 },
        },
      }}
    />
  )
}