import { createSlice } from '@reduxjs/toolkit'
import { DecisionFormFields, currencyValidator } from './constants'

const initialFormState = {
  [DecisionFormFields.contractId.key]: "",
  [DecisionFormFields.desiredTerm.key]: "",
  [DecisionFormFields.renewalDecision.key]: "",
  [DecisionFormFields.actionType.key]: "",
  [DecisionFormFields.zipId.key]: "",
  [DecisionFormFields.tcv.key]: "",
  [DecisionFormFields.forecast.key]: "",
  [DecisionFormFields.budget.key]: "",
  [DecisionFormFields.decisionStatus.key]: "",
  [DecisionFormFields.needByDate.key]: "",
  [DecisionFormFields.contact.key]: "",
  [DecisionFormFields.financePartner.key]: "",
}

const initialErrorState = {
  [DecisionFormFields.contractId.key]: "",
  [DecisionFormFields.desiredTerm.key]: "",
  [DecisionFormFields.renewalDecision.key]: "",
  [DecisionFormFields.actionType.key]: "",
  [DecisionFormFields.zipId.key]: "",
  [DecisionFormFields.tcv.key]: "",
  [DecisionFormFields.forecast.key]: "",
  [DecisionFormFields.budget.key]: "",
  [DecisionFormFields.decisionStatus.key]: "",
  [DecisionFormFields.needByDate.key]: "",
  [DecisionFormFields.contact.key]: "",
  [DecisionFormFields.financePartner.key]: "",
}

const validators = {
  [DecisionFormFields.tcv.key]: currencyValidator,
  [DecisionFormFields.forecast.key]: currencyValidator,
  [DecisionFormFields.budget.key]: currencyValidator,
}

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    formData: initialFormState,
    errors: initialErrorState,
    isDirty: false,
    isValid: false,
  },
  reducers: {
    initializeForm: (state, action) => {
      const { contractId, decisionData } = action.payload
      if (decisionData) {
        state.formData = {
          ...decisionData,
          [DecisionFormFields.contractId.key]: contractId,
        }
      } else {
        formSlice.caseReducers.resetForm(state)
        state.formData[DecisionFormFields.contractId.key] = contractId
      }

    },
    setFormValue: (state, action) => {
      const { field, value } = action.payload
      state.formData[field] = value
      
      const validator = validators?.[field]
      if (validator)
        state.errors[field] = validator(value)

      state.isDirty = true
      state.isValid = Object.values(state.errors).every(field => field === "")
    },
    resetForm: state => {
      state.formData = {...initialFormState}
      state.errors = {...initialErrorState}
      state.isDirty = false
      state.isValid = false
    },
    setFormDirty: (state, action) => {
      state.isDirty = action.payload
    }
  },
  selectors: {
    /** Get the selected rowId normalized for use in the MuiDataGrid. Returns as an array */
    getFormData: state => state.formData,
  }
})

export const { setFormValue, resetForm, initializeForm, setFormDirty } = formSlice.actions

export const { getFormData } = formSlice.selectors

export default formSlice.reducer