import { Stack, Typography } from "@mui/material";

export const SectionHeader = ({
  headerText,
}) => (
  <Stack 
    direction="row" 
    justifyContent="center"
    alignItems="center"
    // mb={2}
    sx={{
      backgroundColor: (theme) => theme.palette.primary.main,
      color: (theme) => theme.palette.primary.contrastText
    }}
  >
    <Typography component="h2" variant="h6">
      {headerText}
    </Typography>
  </Stack>
)
