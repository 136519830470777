import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getExpiringContractsData, setDateFilter } from '../dataSlice';

const columns = [
  'Year',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
  'Total',
]

const CustomTableCell = ({
  value,
  year,
  month,
}) => {
  const dispatch = useDispatch()
  const handleClick = () => dispatch(setDateFilter({year: year, month: month}))

  return (
    <TableCell align="center">
      {
        value === 0 ?
        value :
        <Chip
          color="secondary"
          label={value} 
          onClick={handleClick}
          // color="secondary"
          // sx={{
          //   backgroundColor: (theme) => theme.palette.secondary.main,
          //   // color: (theme) => theme.palette.secondary.contrastText
          // }}
        />
      }
    </TableCell>  
  )
}

export const ContractCalendar = () => {
  const rows = useSelector(getExpiringContractsData)
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) =>
              <TableCell key={column} align="center">{column}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.year}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.year}
              </TableCell>
              <CustomTableCell value={row.jan} year={row.year} month={0} />
              <CustomTableCell value={row.feb} year={row.year} month={1} />
              <CustomTableCell value={row.mar} year={row.year} month={2} />
              <CustomTableCell value={row.apr} year={row.year} month={3} />
              <CustomTableCell value={row.may} year={row.year} month={4} />
              <CustomTableCell value={row.jun} year={row.year} month={5} />
              <CustomTableCell value={row.jul} year={row.year} month={6} />
              <CustomTableCell value={row.aug} year={row.year} month={7} />
              <CustomTableCell value={row.sep} year={row.year} month={8} />
              <CustomTableCell value={row.oct} year={row.year} month={9} />
              <CustomTableCell value={row.nov} year={row.year} month={10} />
              <CustomTableCell value={row.dec} year={row.year} month={11} />
              <TableCell align="center">{row.total}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
