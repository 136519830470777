import { AppBar } from "../components/AppBar"
import { Box, Chip, Container, Drawer, FormControlLabel, Paper, Stack, Switch } from "@mui/material"
import { DataGrid } from "../components/DataGrid"
import styled from "@emotion/styled";
import { SectionHeader } from "../components/SectionHeader";
import { useDispatch, useSelector } from "react-redux";
import { DecisionForm } from "../components/DecisionForm";
import { ContractCalendar } from "../components/ContractCalendar";
import { getExpirationDateFilterLabel, getShowOnlyActiveContracts, setDateFilter, setShowOnlyActive } from "../dataSlice";

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);


export const Home = () => {
  const dispatch = useDispatch()
  const { drawerOpen } = useSelector(state => state.app)
  const showActiveFlag = useSelector(getShowOnlyActiveContracts)
  const handleFilterClear = () => dispatch(setDateFilter({}))
  const handleActiveChange = (event) => dispatch(setShowOnlyActive(event.target.checked))
  const expirationDateLabel = useSelector(getExpirationDateFilterLabel)
  return (
    <>
      <Drawer
        anchor="right"
        variant="persistent"
        open={drawerOpen}
        
      >
        <DecisionForm />
      </Drawer>
      <AppBar />
      <Offset />
      <Stack 
        spacing={3}
        sx={{
          alignItems: 'center',
          mx: 3,
          py: 2,
        }}
      >
        <Stack direction="row" justifyContent="center" width="100%" spacing={3}>
          <Container>
            <Paper sx={{height: "100%"}}>
              <SectionHeader headerText="Filters" />
              <Stack alignItems="flex-start" spacing={1} padding={1}>
                <Box id="filter-panel" width="100%" />
                <FormControlLabel 
                  label="Active:"
                  labelPlacement="start"
                  control={<Switch checked={showActiveFlag} onChange={handleActiveChange} />} 
                />
                {/* {expirationDateLabel && 
                  <FormControlLabel
                    label="Year-Month Filter:"
                    labelPlacement="start"
                    control={<Chip color="primary" sx={{marginLeft: (theme) => theme.spacing(1)}} label={expirationDateLabel} onDelete={handleFilterClear} />}
                  />
                } */}
                {expirationDateLabel && 
                  <Chip color="primary" label={expirationDateLabel} onDelete={handleFilterClear} />
                }
              </Stack>
            </Paper>
          </Container>
          
          <Paper>
            <SectionHeader headerText="Expiring Contracts by Month" />
            <ContractCalendar />
          </Paper>
          {/* <Card>
            <CardHeader title="Expiring Contracts by Month" />
            <CardContent>
              <ContractCalendar />
            </CardContent>
          </Card> */}
        </Stack>
        <Box
          sx={{
            width: '100%',
            maxWidth: { sm: '100%', md: '1700px' }
          }}
        >
          <Paper>
            <SectionHeader headerText="Contract Document Details" />
            <Box display="flex" flexDirection="column" maxHeight="500px">
              <DataGrid />
            </Box>
          </Paper>
        </Box>
      </Stack>
    </>
  )
}