import { createSelector, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const defaultExpirationByMonth = Array(12).keys().reduce((result, val) => ({...result, [val]: 0}), {})

const today = dayjs()

export const defaultDataState = {
  currentRowId: null,
  showOnlyActiveContracts: true,
  rows: [],
  contracts: {},
  decisionData: {},
  expirationDateFilter: {
    year: null,
    month: null,
  },
}

const getActiveRowsSelector = createSelector(
  (state) => state.rows,
  (state) => state.showOnlyActiveContracts,
  (rows, onlyActive) => {
    if (!onlyActive) {
      return rows
    }

    return rows.filter((row) => {
      const expirationDate = dayjs(row.expirationDate)

      // filter out any contracts that are past their expiration date
      return (
        expirationDate.isValid() && (
          today.isSame(expirationDate, 'day') || 
          today.isBefore(expirationDate, 'day')
        )
      )
    })
  }
)

export const dataSlice = createSlice({
  name: 'data',
  initialState: defaultDataState,
  reducers: {
    setRowId: (state, action) => {
      const rowId = action.payload ? action.payload[0] : null
      state.currentRowId = rowId
    },
    setImportData: (state, action) => {
      state.rows = action.payload
      state.contracts = action.payload.reduce((result, row) => ({...result, [row.id]: row}), {})
    },
    saveEntityData: (state, action) => {
      const formData = action.payload
      state.decisionData[state.currentRowId] = formData
    },
    setDateFilter: (state, action) => {
      const {year = null, month = null} = action.payload
      if (year == null || month == null) {
        state.expirationDateFilter.year = null
        state.expirationDateFilter.month = null
      }
      else {
        state.expirationDateFilter.year = year
        state.expirationDateFilter.month = month
      }
    },
    setShowOnlyActive: (state, action) => {
      state.showOnlyActiveContracts = action.payload
    }
  },
  selectors: {
    /** Get the selected rowId normalized for use in the MuiDataGrid. Returns as an array */
    // getRowId: state => state.currentRowId === null ? [] : [state.currentRowId],
    getRowId: createSelector(
      (state) => state.currentRowId,
      (currentRowId) => {
        if (currentRowId === null) {
          return []
        }

        return [currentRowId]
      }
    ),
    getContractId: state => state.currentRowId,
    getAllContracts: state => state.contracts,
    getAllDecisionData: state => state.decisionData,
    getActiveRows: getActiveRowsSelector,
    getFilteredRows: createSelector(
      [
        getActiveRowsSelector,
        (state) => state?.expirationDateFilter?.year,
        (state) => state?.expirationDateFilter?.month,
      ],
      (rows, year, month) => {
        if (year == null || month == null) {
          return rows
        }

        const selectedMonth = dayjs().year(year).month(month)

        const begin = selectedMonth.date(1)
        const end = selectedMonth.date(selectedMonth.daysInMonth())

        return rows.filter((row) => {
          const expirationDate = dayjs(row.expirationDate)

          return (
            expirationDate.isValid() &&
            ( begin.isSame(expirationDate, 'day') || begin.isBefore(expirationDate, 'day') ) &&
            ( end.isSame(expirationDate, 'day') || expirationDate.isBefore(end, 'day'))
          )
        })
      }
    ),
    getDecisionData: state => {
      if (state.currentRowId === null) {
        return null
      } else if (!state.decisionData[state.currentRowId]) {
        return null
      }
      return state.decisionData[state.currentRowId]
    },
    getExpiringContractsData: createSelector(
      getActiveRowsSelector,
      (rows) => {
        const years = []
        const yearMap = {}
        rows.map((row) => {
          const expirationDate = dayjs(row.expirationDate)
          if (!expirationDate.isValid()) {
            return row
          }
          const year = expirationDate.year()
          const month = expirationDate.month()
          if (!years.includes(year)) {
            years.push(year)
          }

          if (!yearMap?.[year]) {
            yearMap[year] = {...defaultExpirationByMonth}
          }
          
          if (!yearMap?.[year]?.[month]) {
            yearMap[year][month] = 1
          } else {
            yearMap[year][month]++
          }
          return row
        })
        
        years.sort()

        // an array of objects that include:
        // year, monthCount, total to be used in the ContractCalendar
        const data = []

        years.forEach((year) => {
          data.push({
            year,
            jan: yearMap[year][0],
            feb: yearMap[year][1],
            mar: yearMap[year][2],
            apr: yearMap[year][3],
            may: yearMap[year][4],
            jun: yearMap[year][5],
            jul: yearMap[year][6],
            aug: yearMap[year][7],
            sep: yearMap[year][8],
            oct: yearMap[year][9],
            nov: yearMap[year][10],
            dec: yearMap[year][11],
            total: Object.values(yearMap[year]).reduce((acc, val) => acc + val, 0)
          })
        })

        return data
      }
    ),
    getShowOnlyActiveContracts: state => state.showOnlyActiveContracts,
    getExpirationDateFilterLabel: state => {
      if (state.expirationDateFilter.month == null) {
        return ""
      }

      const { year, month } = state.expirationDateFilter
      const selectedMonth = dayjs().year(year).month(month)

      return selectedMonth.format('MMM YYYY')
    },
  }
})

export const { 
  setRowId, 
  setImportData, 
  saveEntityData, 
  setDateFilter, 
  setShowOnlyActive 
} = dataSlice.actions

export const {
  getAllContracts,
  getAllDecisionData,
  getRowId,
  getContractId,
  getDecisionData, 
  getExpiringContractsData, 
  getFilteredRows, 
  getShowOnlyActiveContracts, 
  getExpirationDateFilterLabel 
} = dataSlice.selectors

export default dataSlice.reducer