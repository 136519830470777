import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../context/AuthProvider"
import { RoutePaths } from "../constants"

export const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth()

  return isAuthenticated ? (
    <Outlet/>
  ) : (
    <Navigate to={RoutePaths.LOGIN} replace={true} />
  )    
}