import { FormControl, FormLabel, MenuItem, TextField } from "@mui/material";

export const SelectFormField = ({
  fieldId,
  label,
  value,
  onChange = () => {},
  options = [],
}) => {

  const handleChange = (e) => {
    onChange(fieldId, e)
  }

  return (
    <FormControl size="small" fullWidth>
      <FormLabel htmlFor={fieldId} sx={{ fontSize: '0.875rem'}}>{label}</FormLabel>
      <TextField
        size="small"
        id={fieldId}
        name={fieldId}
        variant="outlined"
        value={value}
        onChange={handleChange}
        select
      >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      </TextField>
    </FormControl>
  )
}