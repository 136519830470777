import { Box, Paper } from '@mui/material';
import { LoginForm } from '../components/LoginForm';
import { NewPasswordForm }  from '../components/NewPasswordForm'

// )
export const Login = () => {
  // const isFirstLogin = 
  const handleLogin = (username, password) => {
    // const authDetails = new AuthenticationDetails({
    //   Username: username,
    //   Password: password,
    // });
    // const userData = {
    //   Username: username,
    //   Pool: getUserPool(),
    //   Storage: getStorage(),
    // };
    // const cognitoUser = new CognitoUser(userData);
    // cognitoUser.authenticateUser(authDetails, {
    //   onSuccess: () => {
    //     // login
    //   },
    //   newPasswordRequired: userAttr => {
    //     this.setState({
    //       isFirstLogin: true,
    //       user: cognitoUser,
    //       userAttr: userAttr,
    //     });
    //   },
    // });
  };

  const changePassword = (newPassword) => {
    // const cognitoUser = this.state.user;
    // const userAttr = this.state.userAttr;
    // cognitoUser.completeNewPasswordChallenge(newPassword, userAttr, {
    //   onSuccess: result => {
    //     // login
    //   }
    // });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <LoginForm />
    </Box>
  );

  // return (
  //   <div>
  //     {this.state.isFirstLogin ? (
  //       <NewPasswordForm changePassword={changePassword} />
  //     ) : (
  //       <LoginForm handleLogin={handleLogin} />
  //     )}
  //   </div>
  // );
}