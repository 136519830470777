// import React from 'react';

import {Button} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { getAllContracts, getAllDecisionData } from "../dataSlice";

export const DownloadButton = () => {
  const timestamp = dayjs().format()
  const filename = `Contract-Data-${timestamp}`
  const contracts = useSelector(getAllContracts)
  const decisionData = useSelector(getAllDecisionData)

  const data = {
    contracts,
    decisionData,
  }
  const downloadJSON = () => {
    const jsonData = new Blob([JSON.stringify(data)], { type: 'application/json' });
    const jsonURL = URL.createObjectURL(jsonData);
    const link = document.createElement('a');
    link.href = jsonURL;
    link.download = `${filename}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      color="secondary"
      size="small"
      variant="contained"
      onClick={downloadJSON}
      startIcon={<DownloadIcon />}
    >
      Download Data
    </Button>
  );
}
