import { FormControl, FormLabel, TextField } from "@mui/material";

export const FormField = ({
  fieldId,
  label,
  value,
  onChange = () => {},
  errorText = "",
  slotProps = {},
  disabled = false,
}) => {

  const handleChange = (e) => {
    onChange(fieldId, e)
  }

  return (
    <FormControl size="small">
      <FormLabel htmlFor={fieldId} sx={{ fontSize: '0.875rem'}}>{label}</FormLabel>
      <TextField
        size="small"
        id={fieldId}
        name={fieldId}
        variant="outlined"
        value={value}
        onChange={handleChange}
        error={!!errorText}
        helperText={errorText}
        slotProps={slotProps}
        disabled={disabled}
      />
    </FormControl>
  )
}