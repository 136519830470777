import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice'
import formReducer from './formSlice'
import dataReducer, { defaultDataState } from './dataSlice'

import { setImportData, saveEntityData } from './dataSlice'

const CONTRACTS_KEY = "DASHBOARD_CONTRACTS"

const CONTRACT_DECISION_DATA_KEY = "DASHBOARD_DECISION_DATA"

const dataMiddleware = (store) => (next) => (action) => {
  const result = next(action)

  if (setImportData.match(action)) {
    const { contracts } = store.getState().data
    localStorage.setItem(CONTRACTS_KEY, JSON.stringify(contracts))
  }

  if (saveEntityData.match(action)) {
    const { decisionData } = store.getState().data
    localStorage.setItem(CONTRACT_DECISION_DATA_KEY, JSON.stringify(decisionData))
  }

  return result
}

const contractsFromStorage = localStorage.getItem(CONTRACTS_KEY) ? JSON.parse(localStorage.getItem(CONTRACTS_KEY)) : {}
const decisionDataFromStorage = localStorage.getItem(CONTRACT_DECISION_DATA_KEY) ? JSON.parse(localStorage.getItem(CONTRACT_DECISION_DATA_KEY)) : {}
const persistedState = {
  data: {
    ...defaultDataState,
    rows: Object.values(contractsFromStorage),
    contracts: {...contractsFromStorage},
    decisionData: {...decisionDataFromStorage},
  }
}

export default configureStore({
  reducer: {
    app: appReducer,
    data: dataReducer,
    form: formReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dataMiddleware),
  preloadedState: persistedState,
})