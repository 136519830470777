import { IconButton, Tooltip } from "@mui/material"
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuth } from "../context/AuthProvider"

export const LogoutButton = () => {
  const { logout } = useAuth()
  return (
    <IconButton size="small" onClick={logout} color="inherit">
      <Tooltip id="logout-button" title="Logout">
        <LogoutIcon fontSize="small" />
      </Tooltip>
    </IconButton>
  )
}