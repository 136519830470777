import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import userpool from '../userpool';
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { RoutePaths } from "../constants";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const userFromStorage = userpool.getCurrentUser()
  const [isAuthenticated, setIsAuthenticated] = useState(!!userFromStorage)
  const [user, setUser] = useState(userFromStorage)

  const login = (email, password) => {
    return new Promise((resolve,reject) => {
      const userToAuth = new CognitoUser({
          Username: email,
          Pool: userpool
      });

      userToAuth.setAuthenticationFlowType("USER_PASSWORD_AUTH")

      const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
      });

      userToAuth.authenticateUser(authDetails, {
          onSuccess: (result)=>{
              console.log("login successful");
              setIsAuthenticated(true)
              setUser(userToAuth)
              resolve(result);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            delete userAttributes.email
            delete userAttributes.email_verified;
            userAttributes.family_name = 'Anthony'
            userAttributes.given_name = 'Robert'
            userAttributes.name = 'Robert'
            userToAuth.completeNewPasswordChallenge('11223344AAbbccdd!!', userAttributes, {
              onSuccess: (data) => {
                console.log(data);
              },
              onFailure: (err) => {
                alert(err)
              }
            })
          },
          onFailure: (err) => {
            console.log("login failed", err);
            reject(err);
          }
      });
    });
  };

  const logout = () => {
    if (user)
      user.signOut()
    setIsAuthenticated(false)
    setUser(null)
    navigate(RoutePaths.LOGIN)
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }} >
      {children}
    </AuthContext.Provider>
  )
};

// export a hook that allows us to tap into the auth context
export const useAuth = () => useContext(AuthContext)
