import { Button } from "@mui/material"
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Papa from 'papaparse';
import { useDispatch } from "react-redux";
// import { setImportData } from "../csvSlice";
import { setImportData } from "../dataSlice";

export const DataUploadButton = () => {
  const dispatch = useDispatch()

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      header: true, // If your CSV file has a header row
      complete: (result) => {
        const rows = result.data.map((rowData, index) => {
          const amount = rowData["Contract Amount"]
          const parsedAmount = amount ? parseFloat(amount.replace(/[^0-9.-]+/g, "")) : null
          return {
            id: rowData["Contract ID"],
            expirationDate: rowData["Expiration Date"],
            supplierName: rowData["Supplier Name"],
            oem: rowData["OEM"],
            contractType: rowData["Contract type"],
            chargeOut: rowData["Charge out"],
            expenseType: rowData["Expense Type"],
            effectiveDate: rowData["Effective Date"],
            termType: rowData["Term Type"],
            contractAmount: parsedAmount,
            noticePeriod: rowData["Notice Period"],
            invoiceNumber: rowData["Invoice Number"],
            notes: rowData["Notes"],
          }
        })

        dispatch(setImportData(rows))
      },
    });
  };

  return (
    <Button
      color="secondary"
      size="small"
      component="label"
      variant="contained"
      startIcon={<FileUploadIcon />}
    >
      Upload csv data
      <input type="file" accept=".csv,.xlsx" hidden onChange={handleFileUpload} />
    </Button>
  )
}