import { openDrawer } from "./appSlice"
import { getContractId, getDecisionData, saveEntityData, setRowId } from "./dataSlice"
import { getFormData, initializeForm, setFormDirty } from "./formSlice"

export const setRowSelectionAndInitializeForm = (selectedRowModel) => (dispatch, getState) => {
  // Sets the selected row and initializes the decision form with data
  if (!selectedRowModel.length) {
    dispatch(setRowId(null))
  }
  else {
    dispatch(setRowId(selectedRowModel))

    const decisionData = getDecisionData(getState())
    const contractId = getContractId(getState())
    dispatch(initializeForm({contractId, decisionData}))

    dispatch(openDrawer())
  }
}

export const saveDecisionFormData = () => (dispatch, getState) => {
  const formData = getFormData(getState())

  dispatch(saveEntityData(formData))
  dispatch(setFormDirty(false))
}
