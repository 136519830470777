// import Grid from '@mui/core/Grid'
import dayjs from "dayjs";
import Grid from "@mui/material/Grid2";
import {FormControl, Button, FormLabel, InputAdornment, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "../appSlice";
import { setFormValue } from '../formSlice'
import { SectionHeader } from "./SectionHeader";
import { FormField } from "./FormField";
import { ActionTypeOptions, DecisionFormFields, DesiredTermOptions, RenewalDecisionOptions } from "../constants";
import { SelectFormField } from "./SelectFormField";
import { saveDecisionFormData } from "../thunks";
import { grey } from "@mui/material/colors";

const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  backgroundColor: grey[500],
  '&:hover': {
    backgroundColor: grey[700],
  },
}));

export const DecisionForm = () => {
  const dispatch = useDispatch()
  const close = () => dispatch(closeDrawer());
  const submit = () => dispatch(saveDecisionFormData())
  const handleFieldChange = (fieldName, e) => dispatch(setFormValue({field: fieldName, value: e.target.value}))
  const handleDateFieldChange = (fieldName, date) => dispatch(setFormValue({field: fieldName, value: date}))
  
  const { errors, formData, isDirty, isValid } = useSelector(state => state.form)
  
  return (
    <Grid container direction="column" margin={3} spacing={1} minWidth="500px">
      <SectionHeader headerText="Contract Decision Form" />
      {/* <Stack direction="column" spacing={1}> */}
      <FormField
        fieldId={DecisionFormFields.contractId.key}
        label={DecisionFormFields.contractId.label}
        value={formData[DecisionFormFields.contractId.key]}
        disabled
        // onChange={handleFieldChange}
        // errorText={errors[DecisionFormFields.contractId.key]}
      />
      <Grid container sx={{ width: "100%"}}>
        <Grid>
          <SelectFormField 
            fieldId={DecisionFormFields.desiredTerm.key}
            label={DecisionFormFields.desiredTerm.label}
            value={formData[DecisionFormFields.desiredTerm.key]}
            onChange={handleFieldChange}
            options={DesiredTermOptions}
          />
        </Grid>
        <Grid size={4}>
          <SelectFormField
            fieldId={DecisionFormFields.actionType.key}
            label={DecisionFormFields.actionType.label}
            value={formData[DecisionFormFields.actionType.key]}
            onChange={handleFieldChange}
            options={ActionTypeOptions}
          />
        </Grid>
        <Grid size="grow">
          <SelectFormField 
            fieldId={DecisionFormFields.renewalDecision.key}
            label={DecisionFormFields.renewalDecision.label}
            value={formData[DecisionFormFields.renewalDecision.key]}
            onChange={handleFieldChange}
            options={RenewalDecisionOptions}
          />
        </Grid>
      </Grid>
      <FormField
        fieldId={DecisionFormFields.zipId.key}
        label={DecisionFormFields.zipId.label}
        value={formData[DecisionFormFields.zipId.key]}
        onChange={handleFieldChange}
        errorText={errors[DecisionFormFields.zipId.key]}
      />
      <Grid container sx={{ width: "100%"}}>
        <Grid>

      <FormField
        fieldId={DecisionFormFields.tcv.key}
        label={DecisionFormFields.tcv.label}
        value={formData[DecisionFormFields.tcv.key]}
        onChange={handleFieldChange}
        errorText={errors[DecisionFormFields.tcv.key]}
        slotProps={{
          input: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }
        }}
        />
        </Grid>
        <Grid>

      <FormField
        fieldId={DecisionFormFields.forecast.key}
        label={DecisionFormFields.forecast.label}
        value={formData[DecisionFormFields.forecast.key]}
        onChange={handleFieldChange}
        errorText={errors[DecisionFormFields.forecast.key]}
        slotProps={{
          input: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }
        }}
        />
        </Grid>
      </Grid>
      <FormField
        fieldId={DecisionFormFields.budget.key}
        label={DecisionFormFields.budget.label}
        value={formData[DecisionFormFields.budget.key]}
        onChange={handleFieldChange}
        errorText={errors[DecisionFormFields.budget.key]}
        slotProps={{
          input: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }
        }}
      />
      <FormField
        fieldId={DecisionFormFields.decisionStatus.key}
        label={DecisionFormFields.decisionStatus.label}
        value={formData[DecisionFormFields.decisionStatus.key]}
        onChange={handleFieldChange}
        errorText={errors[DecisionFormFields.decisionStatus.key]}
      />
      <FormControl size="small">
        <FormLabel htmlFor={DecisionFormFields.needByDate.key} sx={{ fontSize: '0.875rem'}}>{DecisionFormFields.needByDate.label}</FormLabel>
        <DatePicker
          size="small"
          id={DecisionFormFields.needByDate.key}
          name={DecisionFormFields.needByDate.key}
          variant="outlined"
          value={dayjs(formData[DecisionFormFields.needByDate.key]) || null}
          onChange={(date) => {
            handleDateFieldChange(DecisionFormFields.needByDate.key, date)
          
          }}
          slotProps={{
            textField: {
                variant: 'outlined',
                size: "small",
            }
          }}
        />
      </FormControl>
      <FormField
        fieldId={DecisionFormFields.contact.key}
        label={DecisionFormFields.contact.label}
        value={formData[DecisionFormFields.contact.key]}
        onChange={handleFieldChange}
        errorText={errors[DecisionFormFields.contact.key]}
      />
      <FormField
        fieldId={DecisionFormFields.financePartner.key}
        label={DecisionFormFields.financePartner.label}
        value={formData[DecisionFormFields.financePartner.key]}
        onChange={handleFieldChange}
        errorText={errors[DecisionFormFields.financePartner.key]}
      />
      {/* </Stack> */}
      <Grid container flexDirection="row" justifyContent="space-between">
        <CancelButton
          onClick={close}
          variant="contained"
        >
          Close
        </CancelButton>
        <Button
          disabled={!isDirty || !isValid}
          onClick={submit}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </Grid>
    </Grid>
  )
}
